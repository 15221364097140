import * as React from 'react';
import { Layout } from '../components/Layout';
import { PostsList } from '../components/PostsList';
import { Section } from '../components/Section';
import { TopBar } from '../components/TopBar';
import usePostsList from '../hooks/usePostsList';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

const PostsPage = () => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
  const edges = usePostsList();
  return (
    <Layout title={siteTitle} description={siteSubtitle} useChakra={false}>
      <TopBar />
      <Section title="Posts">
        <PostsList postsList={edges} />
      </Section>
    </Layout>
  );
};

export default PostsPage;
