import { useStaticQuery, graphql } from 'gatsby';

const usePostsList = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query PostsListTemplate {
        allMarkdownRemark(
          filter: { frontmatter: { template: { eq: "post" }, draft: { ne: true } } }
          sort: { order: DESC, fields: [frontmatter___date] }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                date
                category
                description
              }
            }
          }
        }
      }
    `,
  );

  return allMarkdownRemark.edges;
};

export default usePostsList;
