import { css } from '@emotion/react';
import React from 'react';
import { Link } from 'gatsby';
import { Edge } from '../../types';
import { color, fontSize, mediaQuery } from '../../constants/style';

type Props = {
  postsList: Array<Edge>;
};

export const PostsList = ({ postsList }: Props) => (
  <div css={styleMain}>
    {postsList.map((edge: Edge, index: number) => {
      return (
        <div css={styleItem} key={'post' + index}>
          <span>{edge.node.frontmatter.date}: </span>
          <span css={styleTitle}>
            {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore */}
            <Link css={styleLink} to={edge.node.fields.slug}>
              {edge.node.frontmatter.title}
            </Link>
          </span>
        </div>
      );
    })}
  </div>
);

const styleMain = css`
  padding: 0px 10% 0px;

  ${mediaQuery.sm} {
    padding: 0%;
  }
`;

const styleItem = css`
  padding: 0px 10px 10px 10px;
  margin: 3px;
  font-size: ${fontSize.text};
  transition: all 0.2s;
  border-radius: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const styleTitle = css`
  font-size: ${fontSize.text};
  font-weight: bold;
  color: ${color.base};
`;

const styleLink = css`
  color: inherit;
  &:hover {
    color: inherit;
  }
`;
